const PORTFOLIO_DATA = {
    digitalgames: {
        id: 1,
        category:'Digital Games',
        generalDescription: 'I’ve worked on mobile games, indie projects, and AAA titles as a creative director, narrative designer, writer, and story consultant. My work runs the gamut from world building and story arcs to item names and dialogue barks. I’ve navigated the choppy waters of decades of canon in legacy IPs, and I’ve built new settings from the ground up and the cosmology down. Whether on a team of hundreds, in the mix of a writing room, or alone at my desk, I love it.',
        routename: 'digitalgames',
        displayImage: '/images/portfolio/digitalgames/Header.jpeg',
        portfolioitems: [
            {
                id:1,
                title: 'Project L.A.M.B.O. - Unreleased Digital Miniatures Game',
                publisher: 'Wizards of the Coast',
                contribution: 'Narrative Design',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:2,
                title: 'Project Penguin - Unreleased Digital Card Game',
                publisher: 'Wizards of the Coast',
                contribution: 'Narrative Design',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:3,
                title: 'Project Turtles - Unreleased Collectible Digital Object Game',
                publisher: 'Wizards of the Coast',
                contribution: 'Narrative Design',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:4,
                title: 'Project Bunnies - Unreleased Digital Object Game',
                publisher: 'Wizards of the Coast',
                contribution: 'Narrative Design',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:5,
                title: 'Uncivilized: The Goblin Game - Unreleased Strategy Game',
                publisher: 'Wizards of the Coast',
                contribution: 'Narrative Design',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:6,
                title: 'Tiny Adventures Facebook Game',
                publisher: 'Wizards of the Coast',
                contribution: 'Narrative Design',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:7,
                title: 'D&D Online: Shadowfell Conspiracy',
                publisher: 'Turbine',
                contribution: 'Lore Master',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:8,
                title: 'D&D Online: Menace of the Underdark',
                publisher: 'Turbine',
                contribution: 'Lore Master',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:9,
                title: 'Magic: The Gathering - Duels of the Planeswalkers',
                publisher: 'Wizards of the Coast',
                contribution: 'Writing',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:10,
                title: 'Lords of Waterdeep: Mobile',
                publisher: 'Playdek',
                contribution: 'Design',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:11,
                title: 'Neverwinter',
                publisher: 'Perfect World',
                contribution: 'Lore Master',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:12,
                title: 'Sword Coast Legends',
                publisher: 'n-Space',
                contribution: 'Lore Master',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:13,
                title: 'Baldurs Gate: Enhanced Edition',
                publisher: 'Beamdog',
                contribution: 'Story Designer',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:14,
                title: 'Baldurs Gate: Enhanced Edition - Siege of Dragonspear',
                publisher: 'Beamdog',
                contribution: 'Story Designer',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:15,
                title: 'Baldurs Gate II: Enhanced Edition',
                publisher: 'Beamdog',
                contribution: 'Story Designer',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:16,
                title: 'Icewind Dale: Enhanced Edition ',
                publisher: 'Beamdog',
                contribution: 'Story Designer',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:17,
                title: 'Dungeons & Dragons: Warbands',
                publisher: 'Atari',
                contribution: 'Lore Master',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:18,
                title: 'Idle Champions of the Forgotten Realms',
                publisher: 'Codename',
                contribution: 'Lore Master',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:19,
                title: 'Warriors of Waterdeep',
                publisher: 'Ludia',
                contribution: 'Lore Master',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:20,
                title: 'Dungeons & Dragons: Heroes of Neverwinter',
                publisher: 'Liquid Entertainment',
                contribution: 'Lore Master',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:21,
                title: 'Baldurs Gate 3',
                publisher: 'Larian',
                contribution: 'Lore Master, Story Designer',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:22,
                title: 'Monochrome RPG',
                publisher: 'Monochrome Workshop',
                contribution: 'Writing',
                description: 'I wrote dialogue and contributed some quest and character design to <i>Monochrome RPG</i> in its early stages, joining a scrappy team to collaborate on a truly unique game. Other obligations drew me away, but the project holds a special place in my heart.',
                imageUrl: '/images/portfolio/digitalgames/monochrome.jpg',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:23,
                title: 'BlazeSky',
                publisher: 'Double Robot',
                contribution: 'Story Consultant',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:24,
                title: 'Solasta',
                publisher: 'Tactical Adventures',
                contribution: 'Story Consultant',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:25,
                title: 'Destiny 2: Shadowkeep',
                publisher: 'Bungie',
                contribution: 'Writing',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:26,
                title: 'Destiny 2: Beyond Light',
                publisher: 'Bungie',
                contribution: 'Writing',
                description: 'I contributed to two expansions for <i>Destiny 2</i>, but the best bit has been my story for the Kentarch 3—a fireteam of Guardians who went into the Black Garden in search of a dangerous artifact and discovered Darkness within themselves. Luke Smith said elements of the story gave him “nerd chills,” and it inspired players to make a fan film of the story as well as fan art of the characters.',
                imageUrl: '/images/portfolio/digitalgames/destiny2.jpg',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'y', 
            },
            {
                id:27,
                title: 'Rogue Company',
                publisher: 'Hi-Rez',
                contribution: 'Narrative Design',
                description: 'I worked with Hi-Rez to build up the backstories of their characters as well as write in-game dialogue. I also had a blast with some worldbuilding and other narrative design that has yet to be revealed.',
                imageUrl: '/images/portfolio/digitalgames/roguecompany.jpeg',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'y', 
            },
            {
                id:28,
                title: 'Unannounced Mobile Game',
                publisher: 'Scopely',
                contribution: 'Narrative Design',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:29,
                title: 'Max Mustard',
                publisher: 'Toast VR',
                contribution: 'Story Consultant',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:30,
                title: 'Unannounced Mobile Game',
                publisher: 'ChangYou',
                contribution: 'Narrative Designer',
                description: '',
                imageUrl: 'null',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'n', 
            },
            {
                id:31,
                title: 'Mystic Kingdoms',
                publisher: 'Playgig',
                contribution: 'Creative Director',
                description: 'As Creative Director I designed the IP for Mystic Kingdoms and had the privilege of leading a great team of writers and artists responsible for world, story, and character development.',
                imageUrl: '/images/portfolio/digitalgames/massive_4_622f2de419.webp',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'y', 
            }
        ] 
    },
    tabletopgames: {
        id: 2,
        category:'Table Top Games',
        generalDescription: 'I’ve worked on more tabletop games than I can remember, but I’ve done my best to record what I recall. I know that I acted as rules developer and lore master for many more titles, and there are some things I wrote for D&D’s organized play that I’m sure I’ve missed, but this page captures most of my major contributions.',
        routename: 'tabletopgames',
        displayImage: '/images/Portfolio/TabletopGames/Header.jpg',
        portfolioitems: [
            {
              id: 1,
              title: 'dScryb Text',
              publisher: 'dScryb',
              contribution: 'Writing, Consultation',
              description: 'David Shulman and Ben Guth created dScryb to provide game masters with inspiring read-aloud text. I had the good fortune to connect with David as the project was kicking off, and so I’ve been a regular contributor providing descriptions of monsters, characters, locations, and more.',
              imageUrl: '/images/Portfolio/TabletopGames/dScryb.png',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'y'
            },
            {
              id: 2,
              title: 'Funkoverse: DC Comics 100',
              publisher: 'Funko',
              contribution: 'Rules Development, Technical Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 3,
              title: 'Funkoverse: DC Comics 101',
              publisher: 'Funko',
              contribution: 'Rules Development, Technical Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 4,
              title: 'Funkoverse: Golden Girls 100',
              publisher: 'Funko',
              contribution: 'Rules Development, Technical Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 5,
              title: 'Funkoverse: Harry Potter 100',
              publisher: 'Funko',
              contribution: 'Rules Development, Technical Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 6,
              title: 'Funkoverse: Harry Potter 101',
              publisher: 'Funko',
              contribution: 'Rules Development, Technical Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 7,
              title: 'Funkoverse: Rick and Morty 100',
              publisher: 'Funko',
              contribution: 'Rules Development, Technical Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 8,
              title: 'Advanced Bestiary',
              publisher: 'Green Ronin',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 9,
              title: 'Races of Renown: Plot & Poison',
              publisher: 'Green Ronin',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 10,
              title: 'Dragon Compendium Vol 1',
              publisher: 'Paizo',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 11,
              title: 'Beneath Haunted Halls',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 12,
              title: 'Betrayal at Baldur\'s Gate',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 13,
              title: 'Complete Mage',
              publisher: 'Wizards of the Coast',
              contribution: 'Rules Development',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 14,
              title: 'Cormyr: The Tearing of the Weave',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 15,
              title: 'D&D Icons: Legend of Drizzt',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 16,
              title: 'D&D Miniatures: Aberrations',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 17,
              title: 'D&D Miniatures: Angelfire',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 18,
              title: 'D&D Miniatures: Blood War',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 19,
              title: 'D&D Miniatures: Colossal Red Dragon',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: 'It’s a GIANT miniature. How cool is that?',
              imageUrl: '/images/Portfolio/TabletopGames/colred.jpg',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'y'
            },
            {
              id: 20,
              title: 'D&D Miniatures: Deathknell',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 21,
              title: 'D&D Miniatures: Desert of Desolation',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 22,
              title: 'D&D Miniatures: Dungeons of Dread',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 23,
              title: 'D&D Miniatures: Gargantuan Blue Dragon',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 24,
              title: 'D&D Miniatures: Legend of Drizzt',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 25,
              title: 'D&D Miniatures: Monster Gift Set',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 26,
              title: 'D&D Miniatures: Night Below',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 27,
              title: 'D&D Miniatures: Underdark',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 28,
              title: 'D&D Miniatures: Unhallowed',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 29,
              title: 'D&D Miniatures: War Drums',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 30,
              title: 'D&D Miniatures: War of the Dragon Queen',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 31,
              title: 'D&D Starter Set',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 32,
              title: 'D20 Menace Manual',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 33,
              title: 'Dreamblade: Night Fusion',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 34,
              title: 'Dungeon Master\'s Guide 2: 4th Edition',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 35,
              title: 'Dungeon Master\'s Guide: 4th Edition',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 35.5,
              title: 'Dungeon Master\'s Guide: 5th Edition',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 36,
              title: 'Dungeons & Dragons Basic Game (black dragon)',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 37,
              title: 'Dungeons & Dragons Basic Game (blue dragon)',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 38,
              title: 'Dungeons & Dragons Cartoon: Animated Series Handbook',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 39,
              title: 'Ed Greenwood Presents Elminster\'s Forgotten Realms',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 40,
              title: 'Fall of Frostsilver',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 41,
              title: 'Fiend Folio',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 42,
              title: 'Ghosts of Dragonspear Castle',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 43,
              title: 'Halls of Undermountain',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 44,
              title: 'Hoard of the Dragon Queen',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 45,
              title: 'Into the Unk nown: Dungeon Survival Handbook',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 46,
              title: 'Legacy of the Crystal Shard',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 47,
              title: 'Lords of Waterdeep',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 48,
              title: 'Lords of Waterdeep: Scoundrels of Skullport',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 49,
              title: 'Lost Crown of Neverwinter',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 50,
              title: 'Magic Item Compendium',
              publisher: 'Wizards of the Coast',
              contribution: 'Rules Development',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 51,
              title: 'Magic: The Gathering - Innistrad',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 52,
              title: 'Magic: The Gathering - Return to Ravnica',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 53,
              title: 'Magic: The Gathering - Scars of Mirrodin',
              publisher: 'Wizards of the Coast',
              contribution: 'Rules Development',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 54,
              title: 'Magic: The Gathering - Zendikar',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 55,
              title: 'Monster Manual III',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 56,
              title: 'Monster Manual IV',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 57,
              title: 'Monster Manual V',
              publisher: 'Wizards of the Coast',
              contribution: 'Rules Development',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 58,
              title: 'Monster Manual: 4th Edition',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 59,
              title: 'Monster Manual: 5th Edition',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 60,
              title: 'Monster Vault',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 61,
              title: 'Mordenkainen\'s Magnificent Emporium',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 61.5,
              title: 'Mordenkainen Presents: Monsters of the Multiverse',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 62,
              title: 'Mordenkainen\'s Tome of Foes',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 63,
              title: 'Murder in Baldur\'s Gate',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Lore Master',
              description: 'Of all the D&D products I worked on, including helping to design two editions of the game, this adventure represents the project about which I am most proud. It comes closest to my aspirations of what a tabletop RPG adventure could look like and provide. Alas, compromises had to be made, but the result is still fantastic.',
              imageUrl: '/images/Portfolio/TabletopGames/MurderInBaldursGateCover.jpg',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'y'
            },
            {
              id: 64,
              title: 'Murder in Baldur\'s Gate: D&D Encounters Launch Weekend',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 65,
              title: 'Neverwinter Campaign Setting',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Rules Development',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 66,
              title: 'Neverwinter Fortune Cards',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 67,
              title: 'Orcs of Stonefang Pass',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Rules Development',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 68,
              title: 'Out of the Abyss',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 69,
              title: 'Player\'s Handbook 2: 4th Edition',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 70,
              title: 'Player\'s Handbook Races: Tieflings',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 71,
              title: 'Player\'s Handbook: 4th Edition',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 72,
              title: 'Player\'s Handbook: 5th Edition',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 73,
              title: 'Player\'s Option: Heroes of Shadow',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Rules Development',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 74,
              title: 'Princes of the Apocalypse',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 75,
              title: 'Races of Eberron',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 82,
              title: 'Scourge of the Sword Coast',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 76,
              title: 'Spell Compendium',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 77,
              title: 'Storm King\'s Thunder',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 78,
              title: 'Sword Coast Adventurer\'s Guide',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 79,
              title: 'Tales from the Yawning Portal',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 80,
              title: 'The Grand History of the Realms',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 81,
              title: 'The Plane Above: Secrets of the Astral Sea',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 82,
              title: 'The Rise of Tiamat',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 83,
              title: 'Three Dragon Ante',
              publisher: 'Wizards of the Coast',
              contribution: 'Rules Development',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 84,
              title: 'Tomb of Annihilation',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 85,
              title: 'Tome of Battle: The Book of Nine Swords',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Rules Development',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 86,
              title: 'Tome of Magic',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 87,
              title: 'Underdark',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 88,
              title: 'Volo\'s Guide To Monsters',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 89,
              title: 'Waterdeep: Dragon Heist',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 90,
              title: 'Waterdeep: Dungeon of the Mad Mage',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 91,
              title: 'Wizards Presents Races and Classes',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 92,
              title: 'Wizards Presents Worlds and Monsters',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 93,
              title: 'Xanathar\'s Guide to Everything',
              publisher: 'Wizards of the Coast',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            }
       ]
    },
    articles: {
        id: 3,
        category:'Articles',
        generalDescription: 'I cut my teeth in the gaming industry through its magazines—back when people bought printed gaming magazines! I broke in with magazine articles and worked my way up the ladder until I was Editor-in-Chief of Dragon Magazine. Over the years I’ve written many articles for magazines and websites. I’ve also edited, performed rules development, and acted as lore master for an unfathomable number of them.',
        routename: 'articles',
        displayImage: '/images/Portfolio/Articles/Header.jpg',
        portfolioitems: [
            {
              id: 1,
              title: 'How To Create a Monster',
              publisher: 'Dragon #276',
              contribution: 'Rules Development',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 2,
              title: 'Age Before Beauty  ',
              publisher: 'Dragon #279 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 3,
              title: 'Reel Heroes  ',
              publisher: 'Dragon #280 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 4,
              title: 'Silent Warriors  ',
              publisher: 'Dragon #289 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 5,
              title: 'Playful Phoenix Fist vs. Four Scholars Boxing  ',
              publisher: 'Dragon #289 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 6,
              title: 'The Little-Lympics  ',
              publisher: 'Dragon #291 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 7,
              title: 'Mortar and Stone  ',
              publisher: 'Dragon #295 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 8,
              title: 'Vanderbolt the First  ',
              publisher: 'Dragon #299',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 9,
              title: 'Parrying Feats',
              publisher: 'Dragon #301',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 10,
              title: 'Meter and Measure  ',
              publisher: 'Dragon #301 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 11,
              title: 'Beasts of the Battlefield  ',
              publisher: 'Dragon #309 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 12,
              title: 'Four Score and 7d6 Ago...  ',
              publisher: 'Dragon #309 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 13,
              title: 'Changing Nature\'s Design  ',
              publisher: 'Dragon #311 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 14,
              title: 'Campaign Classics  ',
              publisher: 'Dragon #315 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 15,
              title: 'Shaken, Not Stirred  ',
              publisher: 'Dragon #316 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 16,
              title: 'Playing Different PCs  ',
              publisher: 'Dragon #317 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 17,
              title: 'All Aboard the Crazy Train  ',
              publisher: 'Dragon #318 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 18,
              title: 'I Scry  ',
              publisher: 'Dragon #319 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 19,
              title: 'The Better Part of Valor  ',
              publisher: 'Dragon #319 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 20,
              title: 'For Love of the Game  ',
              publisher: 'Dragon #320 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 21,
              title: 'Please Pass the Ketchup  ',
              publisher: 'Dragon #321 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 22,
              title: 'In the Beginning There Was Darkness  ',
              publisher: 'Dragon #322 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 23,
              title: 'Dragon Unleashed  ',
              publisher: 'Dragon #323 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 24,
              title: 'The Scoop on Gamer Life  ',
              publisher: 'Dragon #324 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 25,
              title: 'Second Best  ',
              publisher: 'Dragon #324 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 26,
              title: 'Earning XP  ',
              publisher: 'Dragon #325 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 27,
              title: 'The Name of the Game  ',
              publisher: 'Dragon #326 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 28,
              title: 'The Ties that Bind  ',
              publisher: 'Dragon #341 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 29,
              title: 'The Core Mechanic  ',
              publisher: 'Dragon #360 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 30,
              title: 'Ecology of the Death Knight  ',
              publisher: 'Dragon #360 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 31,
              title: 'Ecology of the Fire Archon  ',
              publisher: 'Dragon #361 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 32,
              title: 'Trapped!  ',
              publisher: 'Dragon #366 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 33,
              title: 'Sunderheart, the Funeral City  ',
              publisher: 'Dragon #368 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 34,
              title: 'Delving into Dungeon Delve  ',
              publisher: 'Dragon #372 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 35,
              title: 'Secrets of the City Entombed  ',
              publisher: 'Dragon #372 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 36,
              title: 'Character Concepts: Arcane Options',
              publisher: 'Dragon #374 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 37,
              title: 'Keepers of the Celestian Order  ',
              publisher: 'Dragon #375 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 38,
              title: 'Playing Revenants  ',
              publisher: 'Dragon #376 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 39,
              title: 'Explore Airspur  ',
              publisher: 'Dragon #377 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 40,
              title: 'Know Your Role: Defender',
              publisher: 'Dragon #381 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 41,
              title: 'Channel Divinity: Melora  ',
              publisher: 'Dragon #381 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 42,
              title: 'Channel Divinity: Mielikki  ',
              publisher: 'Dragon #384 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 43,
              title: 'Warlock Basics  ',
              publisher: 'Dragon #384 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 44,
              title: 'Winning Races: Revenant',
              publisher: 'Dragon #387',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 45,
              title: 'Channel Divinity: Tymora\'s Luck',
              publisher: 'Dragon #388',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 46,
              title: 'Winning Races: Minotaurs',
              publisher: 'Dragon #389',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 47,
              title: 'Character Concepts: Corruption',
              publisher: 'Dragon #389',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 48,
              title: 'Sweating Under a Dark Sun',
              publisher: 'Dragon #390',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 49,
              title: 'Winning Races: Dwarves of the All-Father',
              publisher: 'Dragon #391',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 50,
              title: 'Channel Divinity: Kord',
              publisher: 'Dragon #392',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 51,
              title: 'Winning Races: Essential Elves',
              publisher: 'Dragon #392',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 52,
              title: 'Murkroot Trade Moot',
              publisher: 'Dragon #393',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 53,
              title: 'Star Crossed',
              publisher: 'Dragon #393',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 54,
              title: 'Magic of Old',
              publisher: 'Dragon #394',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 55,
              title: 'Winning Races: Shifters in the Realms',
              publisher: 'Dragon #394',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 56,
              title: 'A Little Help from Your Friends  ',
              publisher: 'Dragon #395 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 57,
              title: 'Combat Velocity  ',
              publisher: 'Dragon #399 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 58,
              title: 'Making Race Count, Part 2  ',
              publisher: 'Dragon #405 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 59,
              title: 'Making Race Count, Part 3  ',
              publisher: 'Dragon #408 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 60,
              title: 'Alternative Multiclass Rules  ',
              publisher: 'Dragon #421 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 61,
              title: 'Building Character  ',
              publisher: 'Dragon #422 ',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 62,
              title: 'Game Changers  ',
              publisher: 'Dragon #423 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 63,
              title: 'Combat Options  ',
              publisher: 'Dragon #425 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 64,
              title: 'Skill Options',
              publisher: 'Dragon #426',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 65,
              title: 'Personality Traits  ',
              publisher: 'Dragon #427 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 66,
              title: 'Sleeper in the Tomb of Dreams  ',
              publisher: 'Dungeon #155 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 67,
              title: 'Head in the Clouds  ',
              publisher: 'Dungeon #184',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 68,
              title: 'Head in the Clouds  ',
              publisher: 'Dungeon #184 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 69,
              title: 'NPC Stories: Tavern Regulars',
              publisher: 'Dungeon #188',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 70,
              title: 'Traps of Undermountain',
              publisher: 'Dungeon #201',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 71,
              title: 'Stocking the Shelves',
              publisher: 'Dungeon #205',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 72,
              title: 'Demonomicon of Iggwilv: Shemeshka the Marauder',
              publisher: 'Dungeon #205',
              contribution: 'Rules Development, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 73,
              title: 'Ecology of the Malaugrym',
              publisher: 'Dungeon #206',
              contribution: 'Writing, Lore Master',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 74,
              title: 'Critical Threats: Gnarlcrown',
              publisher: 'Dungeon #91',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 75,
              title: 'Critical Threats: Yu\'thiol Mansecho',
              publisher: 'Dungeon #95',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 76,
              title: 'Steel Dungeons & Iron Dragons',
              publisher: 'Polyhedron #138',
              contribution: 'Writing, Game Design',
              description: 'Issue #138 of Polyhedron featured my second published piece, this one an article supporting the Mask of the Red Death D&D setting. It was the first of a series of four Gothic horror articles detailing ideas for the 1890’s alternate earth where Dracula, Frankenstein, and all manner of other creatures from horror and folklore are all too real. Over my many years at Wizards I tried many times to resurrect this excellent setting for games. Alas, ‘twas not to be.',
              imageUrl: '/images/Portfolio/Articles/steeldungeonsandirondragons.jpg',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'y'
            },
            {
              id: 77,
              title: 'Fear Theater',
              publisher: 'Polyhedron #142',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 78,
              title: 'H.H. Holmes: America\'s First Serial Killer  ',
              publisher: 'Polyhedron #146 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 79,
              title: 'Terrors of Gothic Earth  ',
              publisher: 'Polyhedron #148 ',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 80,
              title: 'The Venturers',
              publisher: 'Shadis Magazine #49',
              contribution: 'Writing',
              description: 'I think the short story in this 1998 issue represents my first professionally published work. I called it “The Venturers,” and I remember that a halfling thief featured prominently is some sort of humorous quest that riffed on D&D tropes. But beyond that, I recall nothing.',
              imageUrl: '/images/Portfolio/Articles/Shadis.jpg',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'y'
            },
            {
              id: 81,
              title: 'Devilish Deeds',
              publisher: 'Wizards.com',
              contribution: 'Writing',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 82,
              title: '“Return to Undermountain” Article Series',
              publisher: 'Wizards.com',
              contribution: 'Writing, Game Design',
              description: 'I’ve been a huge fan of the Forgotten Realms, and one of my favorite parts of the world is the city of Waterdeep and its sprawling dungeon, Undermountain. At the time Wizards had shifted focus onto other settings, but I kept the flame alive with a series of website articles highlighting the most infamous dungeon of D&D.',
              imageUrl: '/images/Portfolio/Articles/returntoundermountain.jpg',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'y'
            },
            {
              id: 83,
              title: '“Designing Your Own Vestiges” Article Series',
              publisher: 'Wizards.com',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 84,
              title: 'Domain of Dread: Sunderheart',
              publisher: 'Wizards.com',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 85,
              title: 'Sleeper in the Tomb of Dreams',
              publisher: 'Wizards.com',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 86,
              title: 'Trapped!',
              publisher: 'Wizards.com',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 87,
              title: 'Ecology of the Fire Archon',
              publisher: 'Wizards.com',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 88,
              title: 'Ecology of the Death Knight',
              publisher: 'Wizards.com',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            },
            {
              id: 89,
              title: '“Design and Development” Article Seriess ',
              publisher: 'Wizards.com',
              contribution: 'Writing, Game Design',
              description: '',
              imageUrl: 'null',
              destinationUrl: 'null',
              buttonText: 'null',
              spotlight: 'n'
            }
       ]
    },
    other: {
        id: 4,
        category:'Other Writing',
        generalDescription: 'When I’m not writing for games, I’m writing something else. I’ve always got a few personal projects going on the side at various stages of completion. When I’ve written something outside my usual oeuvre, I’ll list it here.',
        routename: 'other',
        displayImage: '/images/Portfolio/OtherWriting/Header.jpg',
        portfolioitems: [
              {
                id: 1,
                title: 'More to come...',
                publisher: '',
                contribution: '',
                description: '',
                imageUrl: '',
                destinationUrl: '',
                buttonText: '',
                spotlight: 'n' 
            },
            {
                id: 2,
                title: 'Tavern',
                publisher: '',
                contribution: '',
                description: 'While at Wizards of the Coast, I wrote a TV show treatment and pilot episode for a live-action TV series, and ever since then I’ve dabbled in screenwriting. Unfortunately, Wizards owns that mothballed project, so I don’t have much to tell you about it except that it would have been brilliant.',
                imageUrl: '/images/portfolio/OtherWriting/Tavern.jpg',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'y' 
            },
            {
                id: 3,
                title: 'Screenwriting',
                publisher: '',
                contribution: '',
                description: 'I have more ideas than time, but I’ve been inching a couple of them farther along. They couldn’t be more different. One is something in the vein of The Secret Life of Pets and the other is like if John Wick were a Christmas movie.',
                imageUrl: '/images/portfolio/OtherWriting/screenwriting.png',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'y' 
            },
            {
                id: 4,
                title: '5G & me',
                publisher: '',
                contribution: '',
                description: 'I had the great pleasure of working with Leviathan on a promotional experience for T-Mobile. The work was a sort of like Imagineering for a consumer brand. It was super fun, and the team at Leviathan put together an incredible exhibit.',
                imageUrl: '/images/portfolio/OtherWriting/Leviathan.png',
                destinationUrl: 'null',
                buttonText: 'null',
                spotlight: 'y' 
            },
        ]
    },
    media: {
        id: 5,
        category:'Media Appearances',
        generalDescription: 'Be it a panel at a convention, and interview for a website, or a podcast, I’m always ready to talk about games. I’m particularly interested in discussing principles of good worldbuilding and the underpinning logic of magic or sci-fi technologies in fictional settings.',
        routename: 'media',
        displayImage: '/images/Portfolio/mediaappearances/header.jpg',
        portfolioitems: [
            {
                id: 1,
                title: 'D&D Spotlight Interviews',
                publisher: 'Various Interviews',
                contribution: '2003 - 2015',
                description: '',
                imageUrl: '',
                destinationUrl: '',
                buttonText: '',
                spotlight: 'n' 
            },
            {
                id: 2,
                title: 'D&D Next Playtest',
                publisher: "The Lich-Queen's Beloved Parts 1-5",
                contribution: '2013',
                description: '',
                imageUrl: '',
                destinationUrl: '',
                buttonText: '',
                spotlight: 'n' 
            },
            {
                id: 3,
                title: 'D&D Extra Life',
                publisher: 'Sesion 6',
                contribution: '2015',
                description: "I participated in a 24-hour D&D session for Extra Life, benefiting Children's Miracle Network hospitals. My stint wasn’t for the full 24 hours, though. I learned my lesson at a 24-hour film festival in London, but that’s a tale for another time…",
                imageUrl: '/images/portfolio/mediaappearances/ExtraLife.png',
                destinationUrl: 'https://www.youtube.com/watch?v=XUMnKYq_upA',
                buttonText: 'Extra Life',
                spotlight: 'y' 
            },
            {
                id: 4,
                title: 'The Tome Show Podcast',
                publisher: 'Matt Sernett - Gamer to Gamer',
                contribution: '2017',
                description: '',
                imageUrl: '',
                destinationUrl: '',
                buttonText: '',
                spotlight: 'n' 
            },
            {
                id: 5,
                title: 'Dragon Talk',
                publisher: 'Lore You Should Know - Various Episodes',
                contribution: '2016 - 2018',
                description: 'While I worked at Wizards of the Coast, I was a frequent guest of the Dragon Talk Podcast, particularly the Lore You Should Know segments that delved into D&D canon.',
                imageUrl: '/images/portfolio/mediaappearances/dragontalk.png',
                destinationUrl: 'https://dnd.wizards.com/dragon-talk/podcast-hub',
                buttonText: 'Dragon Talk',
                spotlight: 'y' 
          },
          {
                id: 6,
                title: 'Hook & Chance',
                publisher: 'Worldbuilding Like a Pro I, II, & III',
                contribution: '2021',
                description: 'I had the great pleasure of being a guest on the Hook & Chance podcast to talk about world building. The discussion focused on designing worlds for tabletop RPG play, but many of the ideas and philosophy I expressed apply to world building for any medium.',
                imageUrl: '/images/portfolio/mediaappearances/hookchance.jpg',
                destinationUrl: 'https://www.youtube.com/watch?v=aaY6Z1ScRiY&t=141s',
                buttonText: 'Hook & Chance Podcast',
                spotlight: 'y' 
          }, 
        ]
    }
};

export default PORTFOLIO_DATA;
